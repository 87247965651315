import React from 'react';

export const Hue = {
    "name": "Hue",
    "link": "hue",
    "country": "Vietnam",
    "continent": "Asia",
    "thumb": "https://live.staticflickr.com/65535/50380430596_ffe3fb5ee1_t.jpg",
    "image": "https://live.staticflickr.com/65535/50380430596_1418d72524_o.jpg",
    "caption": "Lose Your Way In The Imperial city",

    "description": "The Imperial City of Hue is situated close to the Truong Son mountain range and in the lower section of the Huong River and Bo River. You will have the opportunity to “travel back in time” visiting all the monasteries and the historical zones of the city. Hue had its amazing history of establishment and development during about 7 centuries. The city’s culture will show you a unique spirit and in a variety of types, which you can enjoy in a different way, like literature, music, people’s lifestyle. You really must admire and contemplate the breath-taking scenery of this expressive city.",

    "nrDays": <p>After arriving (or leaving) by the Hai Van Pass, we recommend <b>2 full days</b> to see all the city. If you have time and want an extra day to spare in Hue, it is not a bad idea. But in our opinion 2 days are enough to see the main points here.
              </p>,

    "whenVisit": <p>There are two good seasons to visit the city. One of them is from <b>May to August</b>. You can stretch on the white sandy beach and immerse yourself in the white wave of Thuan An Beach, or the cool blue water of the legendary Lang Co Beach. The other one is from <b>February to April</b>. Personally, we recommend this one, because it’s when the weather is not too warm yet and there's not many tourists around.
                 </p>,

    "mobility": <p>
                The attractions on the city are not close to each other. So, the idea of getting all the way on foot is difficult here. However, as in other Vietnam cities you have some good ways to move around. One of them is hiring a scooter, which will cost you 100000VND (around 3$) a day. It is the best way if you want full freedom in your timing. The other solution is to use Grab. It is also a good solution but take in consideration there are some areas where it is not possible to use the application and you will need to get a metered taxi.
                <br/><br/>
                To get to Hue you have some solutions. The airport is near the city and it's the best choice if you are short on time. However, we hugely recommend you take the Hai Van Pass if you come from the South! If you are doing the opposite path, leave the city by the Hai Van Pass instead. There is a reason why it is called the <b>must do route</b> for bikes in Vietnam, and you will see amazing landscapes. The train is also a good option for those afraid to drive on a motorbike.
                </p>,

    "safety": <p>Hue is a very safe city to travel. The roads are less chaotic, and the traffic here is lower than in other parts of the country and the street crime is rare. However, be always careful with your personal belongings.
            </p>,

    "itinerary": {
        "description": "As suggested before, we recommend at least 2 days to see the city. It is enough time to see some of the hidden places and to embrace the beauty and the atmosphere of the city. For that we will suggest a 2-day itinerary where you can visit all the hot spots. Of course, it’s only a suggestion and it is possible to adjust as you wish.",
        "days": [
            {
                "description":
                    <p>
                        On the first day start by visiting the Imperial City. You can spend a morning here and take a lunch in Nook Eatery. After lunch you can continue your trip and visit the Royal Temple of Tu Duc, this temple will make you feel like if you are in China. From here, you can take a 10-minute drive until the Tu Hieu Pagoda, it's a small and quitter temple site. To finish the day you can watch the sunset from the rooftop of the Imperial Hotel, a very nice viewpoint. For dinner we recommend something in the night market (closes at 11pm). At night you can take a walk on Truong Tien Bridge, or if want, enjoy the good night life of the city at at Pham Ngu Lao – Chu Van An – Vo Thi Sau walking street.
                    </p>,
                "image": "https://live.staticflickr.com/65535/50504296738_2475cf0490_o.jpg",
                "caption": "Imperial City of Hue."
            },
            {
                "description":
                    <p>
                        For the second day we suggest visiting the abandoned waterpark at Ho Thuy Thien Lake. It is a good place for those who like photography, and a it’s not packed with tourists. For the afternoon we return to the tomb scene and visit the Tomb of Khai Dinh, the Minh Mang Tomb and The Temple of Literature & Linh Mu Pagoda.
                    </p>,
                "image": "https://live.staticflickr.com/65535/50505010961_8a2662a284_o.jpg",
                "caption": "Abandoned waterpark at Ho Thuy Thien Lake."
            },
        ]
    },

    "highlights": [
        {
            "name": "The Imperial City",
            "description": "Also know as “The Citadel”, this is the most popular attraction of the region. The Citadel was the center of the last imperial dynasty of Vietnam that ended in 1945. Since then, this place has seen two wars and was rebuilt. You will spend a nice couple of hours exploring the temples, gates and courtyards that extends by all over the place. The entrance fee is around 5$.",
            "image": "https://live.staticflickr.com/65535/50504296698_ebb5ea4171_o.jpg"
        },
        {
            "name": "The Royal Temple of Tu Duc",
            "description": "A Mausoleum built by the Emperor Tu Duc in advance to his death. Here you will discover some interesting stories related to his lifetime, and may give you the idea that he was not such a nice person. The entrance fee is 100000 VND (3$).",
            "image": "https://live.staticflickr.com/65535/50505010931_c1e91b9be9_o.jpg"
        },
        {
            "name": "The Abandoned Waterpark at Ho Thuy Thien Lake",
            "description": "Supposedly not “a safe place” to go. Probably the main gate will be closed and have a guard there. You can try to bribe the security guard with a few dollars and normally he you let you in. If not the are a couple of other entrances. You can walk a few meters to the left side of the gate and you will find a path that will take you to the dragon statue. Here you have a nice view but be careful inside of the statue.",
            "image": "https://live.staticflickr.com/65535/50505170307_987a73abfc_o.jpg"
        },
        {
            "name": "The Temple of Literature & Linh Mu Pagoda",
            "description": "These two sites on the Perfume Riverbank are also worth to visit. The Temple of Literature is surrounded by bright yellow flowers and 32 giant stone scripts exhibiting the names of students who passed National Examinations under the Nguyen Dynasty. Just a few meters around the corner is the Linh Mu Pagoda, also known as the Palace of the Celestial Lady.",
            "image": "https://live.staticflickr.com/65535/50505010906_e98a08ed07_o.jpg"
        },
        {
            "name": "The Tomb of Khai Dinh",
            "description": "The entrance to the main part of the tomb is around 3$. This in particular is one of the most famous tombs, not only because of the unique architecture but also for the view you have at top over the green countryside and mountains.",
            "image": "https://live.staticflickr.com/65535/50505170262_239505db7e_o.jpg"
        }
    ],

    "food": {
        "description": <p>
            Hue is known for being one of the capital cuisines of the Vietnam. Here, you can find a lot of places where you can taste some delicious street food. Also, we will suggest some good options you can try.
                    </p>,
        "restaurants": [
            {
                "name": "Nook Eatery",
                "description": "The food here is very tasty and you can also taste some of the Vietnamese street food. The interior of the café is quite nice and it follows the country traditions.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50504296618_e1487d3250_o.jpg"
            },
            {
                "name": "Nina’s Cafe",
                "description": "If you want to taste some pork with noodles, salad and spring rolls (traditional Vietnamese cuisine), Nina’s Café is a good option for that.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50505010836_80f0fa49f5_o.jpg"
            },
            {
                "name": "Serene Cuisine Restaurant",
                "description": "It is difficult to hear bad things about this place. With fresh and delicious food and a fantastic service, this is a place you really must go to during your time in the city.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50505010821_695bbaa1dc_o.jpg"
            },
            {
                "name": "Spice Viet Restaurant",
                "description": "With a nice design this “formal” restaurant located in a hotel will present to you a good Vietnamese meal. The service here is very enjoyable.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50504296598_430b18c8d9_o.jpg"
            }
        ]
    },

    "stay": {
        "description": "Hue is a tourist city. So, it will not be difficult to find good accommodations to stay. Here, we are going to suggest you some option for all \"wallets\".",
        "budget": [
            {
                "name": "Sunny A Hotel",
                "description": "If you are searching by a budget option Sunny A Hotel is a good one. With a good location at the city centre and cheap prices you will have a good stay here.",
                "image": "https://live.staticflickr.com/65535/50505170142_483c57255e_o.jpg",
                "link": "/"
            },
            {
                "name": "The Times Hotel",
                "description": "Also consider a budget option, but a little more expensive than the previous one. The Times Hotel is also a nice choice and can include breakfast for 3$ extra.",
                "image": "https://live.staticflickr.com/65535/50505010756_e3665bb94c_o.jpg",
                "link": "/"
            }
        ],
        "mid": [
            {
                "name": "Stop and Go Boutique Homestay Hue",
                "description": "Located in the city centre, this is a good option for backpackers and family travels. IT's a little more expensive, but you will certainly be happy with this choice.",
                "image": "https://live.staticflickr.com/65535/50505010741_41b3001000_o.jpg",
                "link": "/"
            }
        ],
        "high": [
            {
                "name": "Thanh Lich Royal Boutique",
                "description": "If you seek a luxurious hotel, you will not be disappointed with this one. Comes with an indoors pool and gym, and you will also have a good view to the city from your room.",
                "image": "https://live.staticflickr.com/65535/50505010736_9ff55ff21c_o.jpg",
                "link": "/"
            }
        ]
    }
}
